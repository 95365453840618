import React from "react";

const data = [
  { value: 1, display: "January" },
  { value: 2, display: "Febuary" },
  { value: 3, display: "March" },
  { value: 4, display: "April" },
  { value: 5, display: "May" },
  { value: 6, display: "June" },
  { value: 7, display: "July" },
  { value: 8, display: "August" },
  { value: 9, display: "September" },
  { value: 10, display: "October" },
  { value: 11, display: "November" },
  { value: 12, display: "December" }
];

export default data => {
  return data;
};

export const FormMonths = () => {
  return (
    <>
      {data.map((m, index) => (
        <option key={index} value={m.value}>
          {m.display}
        </option>
      ))}
    </>
  );
};
