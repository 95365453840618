import React from "react";

export default (data, productType = "print") => {
  let options = "";

  if (parseInt(data.activeProductSidesCode) === 1) {
    options = <option value="1">Single-Sided</option>;
  } else if (productType === "print" && data.activeProductSidesCode === "_2") {
    options = <option value="2">Double-Sided</option>;
  } else {
    options = (
      <>
        <option value="1">Single-Sided</option>
        <option value="2">Double-Sided</option>
      </>
    );
  }

  return options;
};
