import React, { useState } from "react";
import { useSelector } from "react-redux";
import PaymentForm from "../shopping-cart/utils/payment-form/payment-form";
import http from "../../../../utils/httpServices";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();
  const { system } = useSelector(state => state);
  const paymentGateways = useSelector(state => state.system.paymentGateway);
  const invoice = useSelector(state => state.invoice.dataSet);

  const [paymentFormState, setPaymentFormState] = useState({
    paymentOption: "",
    company: "",
    firstName: "",
    lastName: "",
    creditCardNumber: "",
    creditCardExpirationMonth: 12,
    creditCardExpirationYear: 2021,
    creditCardCvv: "",
    creditCardPostalCode: "",
    email: "",
    invoiceId: invoice._id
  });

  const handlePaymentOptionChange = e => {
    setPaymentFormState({ ...paymentFormState, paymentOption: e.target.value });
  };

  const handlePaymentFormChange = e => {
    setPaymentFormState({
      ...paymentFormState,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitOrder = async e => {
    const itemPrice = parseFloat(invoice.invoicePricing.paymentDue);
    const shippingPrice = 0;

    if (paymentFormState.paymentOption === "payPal") {
      await payPalSubmit(itemPrice, shippingPrice);
    } else if (paymentGateways.authorizeNet.isActive) {
      await authorizeNetSubmit(itemPrice, shippingPrice);
    } else if (paymentGateways.square.isActive) {
      await sqaureSubmit();
    }
  };

  const payPalSubmit = async (itemPrice, shippingPrice) => {
    console.log(108, "payPal submit");

    let payPalLink = process.env.REACT_APP_PAYPAL;
    payPalLink += `?business=${paymentGateways.payPal.email}`;
    payPalLink += `&custom=${invoice._id}`;
    payPalLink += "&cmd=_cart";
    payPalLink += "&upload=1";
    payPalLink += "&no_shipping=1";
    payPalLink += `&return=http://${system.domain}/account/check-out-4`;
    payPalLink += `&cancel_return=http://${system.domain}/account/check-out-2`;
    payPalLink += `&notify_url=${process.env.REACT_APP_PAYPAL_NOTIFY}paymentProcessing/payPal`;

    let item = "&item_number_1=xxx";
    item += "&item_name_1=item-0001";
    item += `&amount_1=${itemPrice}`;
    item += "&quantity_1=1";
    item += `&shipping_1=${shippingPrice}`;

    payPalLink += item;

    console.log(123, payPalLink);
    window.open(payPalLink);
  };

  const authorizeNetSubmit = async (itemPrice, shippingPrice) => {
    try {
      const paymentObj = {
        storeFront: true,
        customerId: invoice.customers._id,
        invoiceId: invoice._id,
        paymentAmount: itemPrice + shippingPrice,

        creditCardNumber: paymentFormState.creditCardNumber,
        creditCardExpiration:
          paymentFormState.creditCardExpirationMonth +
          " " +
          paymentFormState.creditCardExpirationYear,
        creditCardCvv: paymentFormState.creditCardCvv,
        creditCardPostalCode: paymentFormState.creditCardPostalCode

        // shippingMethod: {
        //   ...shippingOptionsState.options[shippingOptionsState.selectedIndex]
        // }
      };

      const { data } = await http.post(
        `paymentProcessing/authorizeNet/capture`,
        paymentObj
      );

      console.log(150, data);

      if (data.error.errorCode === "0x0") {
        history.push("/account/check-out-4");
      } else {
        throw data.error;
      }
    } catch (error) {
      console.log(130, error);
    }
  };

  const sqaureSubmit = async () => {
    try {
      await window.thePaymentForm.requestCardNonce();
    } catch (error) {
      console.log(52, error);
    }
  };

  const paymentInfoObj = {
    paymentTyper: "orderHistory",
    paymentAmount: invoice.invoicePricing.paymentDue,
    theShipping: { options: 0, amount: 0 }
  };

  return (
    <div className="card processing-card mt-5">
      <div className="options">
        <form className="form-horizontal">
          <div className="form-group">
            <div className="col-sm-12">
              <label className="form-radio">
                <input
                  type="radio"
                  name="payment-option-type"
                  value="creditCard"
                  checked={
                    paymentFormState.paymentOption === "creditCard"
                      ? true
                      : false
                  }
                  onChange={e => handlePaymentOptionChange(e)}
                />
                <i className="form-icon"></i> Credit / Debit Card
              </label>

              {paymentGateways.payPal.isActive === true &&
                paymentGateways.payPal.email !== "" && (
                  <label className="form-radio">
                    <input
                      type="radio"
                      name="payment-option-type"
                      value="payPal"
                      checked={
                        paymentFormState.paymentOption === "payPal"
                          ? true
                          : false
                      }
                      onChange={e => handlePaymentOptionChange(e)}
                    />
                    <i className="form-icon"></i> PayPal
                  </label>
                )}
            </div>
          </div>
        </form>
      </div>

      <PaymentForm
        paymentFormState={paymentFormState}
        paymentInfoObj={paymentInfoObj}
        onChange={handlePaymentFormChange}
      />

      <div className="card processing-card">
        <button
          className={`btn btn-primary ${
            (paymentFormState.paymentOption === "" ||
              paymentFormState.termsOfService === false) &&
            "disabled"
          }`}
          onClick={handleSubmitOrder}
        >
          Complete My Order
        </button>
      </div>
    </div>
  );
};
