import React from "react";
import _ from "lodash";

const dateObj = new Date();
const year = dateObj.getFullYear();

const data = _.range(year, year + 10);

export default data => {
  return data;
};

export const FormYears = () => {
  return (
    <>
      {data.map((m, index) => (
        <option key={index} value={m}>
          {m}
        </option>
      ))}
    </>
  );
};
