import React from "react";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";

export default () => {
  const system = useSelector(state => state.system);

  return (
    <div className="storefront-secondary-footer">
      Copyright © 2020 {system.name} All Rights Reserved. <br />
      Powered by E2Print Software
    </div>
  );
};
