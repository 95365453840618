export const GET_SYSTEM_INFORMATION_PENDING = "GET_SYSTEM_INFORMATION_PENDING";
export const GET_SYSTEM_INFORMATION_SUCCESS = "GET_SYSTEM_INFORMATION_SUCCESS";
export const GET_SYSTEM_INFORMATION_FAILURE = "GET_SYSTEM_INFORMATION_FAILURE";

//////////////////////////////////////////////////////
//ACCOUNT/////////////////////////////////////////////
//////////////////////////////////////////////////////
export const USER_LOGIN_PENDING = "USER_LOGIN_PENDING";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_STATUS_TRUE = "USER_STATUS_TRUE";
export const USER_STATUS_FALSE = "USER_STATUS_FALSE";

export const USER_LOGOUT = "USER_LOGOUT";

export const GET_USER_ACCOUNT_PENDING = "GET_USER_ACCOUNT_PENDING";
export const GET_USER_ACCOUNT_SUCCESS = "GET_USER_ACCOUNT_SUCCESS";
export const GET_USER_ACCOUNT_FAILURE = "GET_USER_ACCOUNT_FAILURE";

export const UPDATE_USER_ACCOUNT_PENDING = "UPDATE_USER_ACCOUNT_PENDING";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";
export const UPDATE_USER_ACCOUNT_FAILURE = "UPDATE_USER_ACCOUNT_FAILURE";

export const GET_INVOICES_ITEMS_PENDING = "GET_INVOICES_ITEMS_PENDING";
export const GET_INVOICES_ITEMS_SUCCESS = "GET_INVOICES_ITEMS_SUCCESS";
export const GET_INVOICES_ITEMS_FAILURE = "GET_INVOICES_ITEMS_FAILURE";

export const GET_INVOICE_PENDING = "GET_INVOICE_PENDING";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILURE = "GET_INVOICE_FAILURE";

export const GET_SHOPPING_CART_ITEM_PENDING = "GET_SHOPPING_CART_ITEM_PENDING";
export const GET_SHOPPING_CART_ITEM_SUCCESS = "GET_SHOPPING_CART_ITEM_SUCCESS";
export const GET_SHOPPING_CART_ITEM_FAILURE = "GET_SHOPPING_CART_ITEM_FAILURE";

export const DELETE_SHOPPING_CART_ITEM_PENDING =
  "DELETE_SHOPPING_CART_ITEM_PENDING";
export const DELETE_SHOPPING_CART_ITEM_SUCCESS =
  "DELETE_SHOPPING_CART_ITEM_SUCCESS";
export const DELETE_SHOPPING_CART_ITEM_FAILURE =
  "DELETE_SHOPPING_CART_ITEM_FAILURE";

export const EMPTY_SHOPPING_CART_PENDING = "EMPTY_SHOPPING_CART_PENDING";
export const EMPTY_SHOPPING_CART_SUCCESS = "EMPTY_SHOPPING_CART_SUCCESS";
export const EMPTY_SHOPPING_CART_FAILURE = "EMPTY_SHOPPING_CART_FAILURE";

export const UPDATE_SHOPPING_CART_PARTIAL_PENDING =
  "UPDATE_SHOPPING_CART_PARTIAL_PENDING";
export const UPDATE_SHOPPING_CART_PARTIAL_SUCCESS =
  "UPDATE_SHOPPING_CART_PARTIAL_SUCCESS";
export const UPDATE_SHOPPING_CART_PARTIAL_FAILURE =
  "UPDATE_SHOPPING_CART_PARTIAL_FAILURE";

//////////////////////////////////////////////////////
//ACCOUNT/////////////////////////////////////////////
//////////////////////////////////////////////////////

export const GET_PRODUCT_PENDING = "GET_PRODUCT_PENDING";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";
